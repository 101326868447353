
import NoneContent from '@/components/noneContent.vue'
import orderApi from '@/http/apis/order'
import { defineComponent, computed, onMounted, reactive, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import { Toast } from 'vant'
import { GetQuoteDetailRes, PurchasingRes, QuotationDetailRes } from '@/types/responseType'
export default defineComponent({
  name: 'QuoteInfo',
  components: {
    NoneContent,
  },
  setup() {
    const route = useRoute()
    const state = reactive({
      isScroll: false,
      quote: {} as GetQuoteDetailRes & {
        mDate: string
      },
      detailList: [] as Array<QuotationDetailRes>,
      img: require('@/assets/img/img-none.png'),
      // purchasingAgent: {}, // 采购人
      preparedBy: {} as PurchasingRes, // 制单人
    })
    // 总计
    const total = computed(() => {
      let total: number | string = 0
      for (let index = 0; index < state.detailList.length; index++) {
        const el = state.detailList[index]
        console.log(el)
        if (el.totalPrice === '***') {
          total = '***'
          break
        } else if (!el.totalPrice) {
          ;(total as number) += 0
        } else {
          ;(total as number) += Number(el.totalPrice)
        }
      }
      total !== '***' && (total = Number((total as number).toFixed(2)))
      return total
    })
    // 获取报价单详情
    const getInfo = async () => {
      let data = {} as GetQuoteDetailRes & {
        mDate: string
      }
      if (route.query.platformCode === 'fa') {
        const res = await orderApi.getQuotationDetail(route.params.billno as string)
        data = res.data
      } else if (route.query.platformCode === 'fb') {
        const res = await orderApi.getFBQuotationDetail(route.params.billno as string)
        data = res.data
      }

      // console.log(data, '线上报价单详情')
      let mDate = ''
      let temDate = ''
      if (data.quotationCompletionDate) {
        temDate = JSON.parse(JSON.stringify(data.quotationCompletionDate))
      }
      if (temDate) {
        // 小于10补全位数
        const padStart = (string: string, targetLength: number, padString: string) => {
          while (string.length < targetLength) {
            string = padString + string
          }
          return string
        }
        let _date: Date | number = new Date(temDate.replace(/-/g, '/'))
        _date = _date.setDate(_date.getDate() + 30)
        const a = new Date(_date)
        const y = a.getFullYear()
        const m = padStart((a.getMonth() + 1).toString(), 2, '0')
        const d = padStart(a.getDate().toString(), 2, '0')
        const h = padStart(a.getHours().toString(), 2, '0')
        const mm = padStart(a.getMinutes().toString(), 2, '0')
        const s = padStart(a.getSeconds().toString(), 2, '0')
        mDate = `${y}-${m}-${d} ${h}:${mm}:${s}`
      }
      state.quote = data
      state.quote.mDate = mDate
      // state.purchasingAgent = data.merchandiser
      state.preparedBy = data.purchasing
      state.detailList = data.quotationDetail
    }
    // 复制
    const copy = () => {
      const input = document.createElement('input')
      input.value = state.quote.quotationNumber
      document.body.appendChild(input)
      input.select()
      document.execCommand('Copy')
      document.body.removeChild(input)
      Toast.success('复制成功')
    }
    onMounted(() => {
      getInfo()
    })

    return {
      ...toRefs(state),
      total,
      copy,
    }
  },
})
